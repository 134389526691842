<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="授信进度">
          <el-col :span="24">
            <el-steps :active="stepActiveIndex" finish-status="success">
              <el-step title="申请" />
              <el-step title="补件" />
              <el-step title="资方审批" />
              <el-step title="资方放款" />
              <el-step title="还款" />
              <el-step title="完成" />
            </el-steps>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="用信信息">
          <el-col :span="12">
            <el-form-item label="用信单号">
              <p>{{ utils.isEffectiveCommon(useDetail.useCode) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请方">
              <p>{{ utils.isEffectiveCommon(useDetail.customerName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请金额(元)">
              <p>{{ utils.moneyFormat(useDetail.applyAmount, 2) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="应收账款信息">
          <el-col :span="12">
            <el-form-item label="凭证编号">
              <p>{{ utils.isEffectiveCommon(payableInfoVo.sfNo) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="应收金额">
              <p>{{ utils.moneyFormat(payableInfoVo.amount, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应收期限">
              <p>{{ utils.dateFormat(payableInfoVo.paymentTerm) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="!_.isEmpty(payableInfoVo.filesList)" :span="12">
            <el-form-item label="凭证文件">
              <p v-for="(item, index) in payableInfoVo.filesList" :key="index">
                <a href="javascript:" class="text-btn" @click="utils.downloadP('other', item.url)">{{ item.name }}</a>
              </p>
            </el-form-item>
          </el-col>
          <el-col v-if="_.isEmpty(payableInfoVo.filesList)" :span="12">
            <el-form-item label="凭证文件">
              <p>-</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="发票信息">
          <el-col :span="12">
            <el-form-item label="发票信息">
              <a href="javascript:" class="text-btn" @click="clickDialog('invoices')">点击查看</a>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="付款计划信息">
          <el-col :span="12">
            <el-form-item label="付款计划信息">
              <p v-for="(item, index) in voucherFilesList" :key="index">
                <a href="javascript:" class="text-btn" @click="utils.downloadP('use', item.url)">{{ item.name }}</a>
              </p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="授信信息">
          <el-col :span="24">
            <el-form-item label="关联授信">
              <p>{{ utils.isEffectiveCommon(creditDetail.credit_code) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="资方">
              <p>{{ utils.isEffectiveCommon(creditDetail.cap_name) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授信期限">
              <p>{{ creditDetail.effective_start===undefined ? '' : (creditDetail.effective_start + ' 至 ' + creditDetail.effective_end) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总授信额度(元)">
              <p>{{ utils.moneyFormat(creditDetail.pass_amount, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可用授信额度(元)">
              <p>{{ utils.moneyFormat(creditDetail.available_aomunt, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已用信额度(元)">
              <p>{{ utils.moneyFormat(creditDetail.loan_amount, 2) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-product-inner :product-detail="productDetail" />
        <ics-page-inner title="收款信息">
          <el-col :span="12">
            <el-form-item label="收款人">
              <p>{{ utils.isEffectiveCommon(bankFilesInfo.firmName) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="method !== 0" />
          <el-col v-if="method !== 0" :span="12">
            <el-form-item label="收款银行">
              <p>{{ utils.isEffectiveCommon(bankFilesInfo.bankName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款账户">
              <p>{{ utils.isEffectiveCommon(bankFilesInfo.bankAccount) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="回款信息">
          <el-col :span="12">
            <el-form-item prop="type" label="回款企业类型" :rules="{ required: true, message: '请选择回款企业类型', trigger: 'change' }">
              <el-radio-group v-model="appForm.type" :disabled="disabledAccount">
                <el-radio v-for="item in constants.collectionCompanyType" :key="item.value" :label="item.value">
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item prop="capAccount" label="资金方企业账户" :rules="{ required: true, message: '请选择资金方企业账户', trigger: 'change' }">
              <el-select v-model="appForm.capAccount" :disabled="disabledAccount" placeholder="请选择资金方企业账户" style="width: 100%;" filterable>
                <el-option v-for="item in accountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item prop="customerAccount" label="融资方企业账户" :rules="{ required: true, message: '请输入融资方企业账户', trigger: 'blur' }">
              <el-input v-model.trim="appForm.customerAccount" :disabled="disabledAccount" placeholder="请输入放款账户" @input="(v) => (appForm.customerAccount = v.replace(/\s/g, ''))" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="放款信息">
          <el-col :span="12">
            <el-form-item label="放款账户" prop="loanAccount" :rules="{ required: true, message: '请输入放款账户', trigger: 'blur' }">
              <el-input v-model.trim="appForm.loanAccount" :disabled="disabledAccount" placeholder="请输入放款账户" @input="(v) => (appForm.loanAccount = v.replace(/\s/g, ''))" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="放款金额">
              <p>{{ utils.moneyFormat(appForm.loanAmount, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="付款金额">
              <p>{{ utils.moneyFormat(appForm.amount, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="用款期限" prop="useDay" :rules="{ required: true, validator: validateUseDay, trigger: 'blur' }">
              <el-input v-model="appForm.useDay" :disabled="disabledAccount" placeholder="请输入用款期限" @input="(v) => (appForm.useDay = v.replace(/\D|^/g,''))" @change="changeUseDay" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="到期日">
              <p>{{ utils.dateFormat(useDayDate) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.invoice.visible" :title="dialog.invoice.title" cancel-title="返回" class="form-dialog" :show-submit="false">
      <div class="partition-table">
        <el-table :data="invoicesList" border style="width: 100%" class="table-input" max-height="250">
          <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
          <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
          <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalAmount" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">下载</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import IcsProductInner from './components/product-inner'
export default {
  components: {IcsDialogInner, IcsProductInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      useCode: this.$route.query.useCode,
      taskId: this.$route.query.taskId,
      stepActiveIndex: 0,
      appForm: {
        type: '',
        capAccount: '',
        useDay: '',
        customerAccount: '',
        loanAccount: '',
        amount: '',
      },
      useDayDate: '',
      method: '',
      bankKey: 0,
      productKey: 0,
      disabledAccount: false,
      useDetail: {},
      creditDetail: {},
      contractBaseInfo: {},
      payableInfoVo: {},
      productDetail: {},
      bankFilesInfo: {},
      invoicesList: [],
      voucherFilesList: [],
      accountList: [],
      dialog: {
        invoice: {
          visible: false,
          title: '查看发票信息'
        },
        loan: {
          visible: false,
          title: '上传放款凭证'
        }
      },
      rules: {}
    }
  },
  created() {
    if (this.useCode) {
      this.getDetail()
      this.getAccountList()
    }
  },
  methods: {
    getDetail() {
      this.loading.detail = true
      this.api.use.useDetails({useCode: this.useCode}).then(result => {
        const data = result.data.data || {}
        this.useDetail = data.use
        this.appForm.loanAmount = data.use.passAmount
        this.appForm.amount = data.use.passAmount
        this.creditDetail = JSON.parse(data.use.creditSnapshot)
        this.contractBaseInfo = data.credit.contractBaseInfo
        this.payableInfoVo = data.payableInfoVo
        const productJson = JSON.parse(data.credit.snapshot.productJson) || {}
        productJson.penalTYtRate = productJson.penaltytRate
        this.productDetail = productJson
        if (Number(productJson.repaymentMethod) === 1) {
          this.method = 1
        } else {
          this.method = 0
        }
        if (JSON.stringify(data.makeLoansVo) !== '{}') {
          const makeLoansVo = data.makeLoansVo
          const description = JSON.parse(data.makeLoansVo.description)
          this.appForm.type = description.type
          this.appForm.capAccount = description.capAccount
          this.appForm.customerAccount = description.customerAccount
          this.appForm.amount = makeLoansVo.amount
          this.appForm.useDay = makeLoansVo.useDay
          this.appForm.loanAccount = makeLoansVo.payerAccountNumber
          this.disabledAccount = true
          this.changeUseDay(makeLoansVo.useDay)
        }
        this.bankFilesInfo = JSON.parse(data.use.bankJson)
        data.files.forEach(item => {
          if (item.suffix === 'Voucher') {
            this.voucherFilesList.push(item)
          }
        })
        switch (data.use.flowStateCode) {
          case 'Wait':
            this.stepActiveIndex = 0
            break
          case 'UseSupplement':
            this.stepActiveIndex = 1
            break
          case 'UseAudit':
            this.stepActiveIndex = 2
            break
          case 'UseAmount':
            this.stepActiveIndex = 3
            break
          case 'Repaid':
            this.stepActiveIndex = 4
            break
          case 'Fail':
            this.stepActiveIndex = 6
            break
          case 'Completed':
            this.stepActiveIndex = 6
            break
          default:
            this.stepActiveIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    validateUseDay (rule, value, callback) {
      if (value) {
        if (Number(value) <= 0) {
          callback(new Error('用款期限不能小于1'))
        } else if (Number(value) < Number(this.productDetail.minCycle)) {
          callback(new Error('用款期限不能小于于免收费期限'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入用款期限'))
      }
    },
    getAccountList () {
      this.api.system.account.getTakeEffectList().then(result => {
        this.accountList = result.data.data || []
      }).finally(() => {
      })
    },
    clickDialog (type) {
      if (type === 'invoices') {
        this.invoicesList = this.payableInfoVo.invoicesList || []
        this.dialog.invoice.visible = true
      } else if (type === 'loan') {
        this.dialog.loan.visible = true
      } else {
        return false
      }
    },
    changeUseDay (val) {
      if (val) {
        let data = { day: val }
        this.api.use.timePlus(data).then(result => {
          this.useDayDate = result.data.data
        }).finally(() => {
        })
      } else {
        this.useDayDate = ''
      }
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        const data = this._.cloneDeep(this.appForm)
        const description = {
          type: data.type,
          capAccount: data.capAccount,
          customerAccount: data.customerAccount
        }
        let interestBeforePrincipal = ''
        if (this.productDetail.repayment === 'firstRest') {
          interestBeforePrincipal = 1
        } else {
          interestBeforePrincipal = 0
        }
        const info = {
          amount: data.amount,
          useDay: data.useDay,
          payeeAccountNumber: this.bankFilesInfo.bankAccount,
          payerAccountNumber: data.loanAccount,
          interestBeforePrincipal: interestBeforePrincipal,
          isChujin: 0,
          useCode: this.useCode,
          description: JSON.stringify(description)
        }
        this.api.contract.isContractSign({useCode: this.useCode}).then(result => {
          if (result.data.success === true) {
            this.api.eAccount.makeLoans(info).then(result => {
              if (result.data.success === true) {
                this.loading.submit = false
                this.$message.success('操作成功')
                this.$router.back()
              } else {
                this.$message.error(result.data.data.message)
                this.loading.submit = false
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.$message.error(result.data.message)
            this.loading.submit = false
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
